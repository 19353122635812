@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --dark-bg: #13132c;
    --card-bg: #2b2b4b;
    --element-card-bg: #363659;
    --placeholder-dark: #4c4c6c;

    --combobox-on: #7345ea;
    --combobox-off: #4c4c6c;

    --table-odd-row: #38385f;

    --primary-button-bg: #7345ea;

    --light-grey: #55556f;
    --middle-grey: #232349;
    --dark-grey: #1f1f41;

    --text-secondary: #6d6d87;
    --text-form-content: #d6d6ff;
    --text-button: #d9d9d9;

    --online-state: #a8ffc5;

    --online-state-1: #26ca88;
    --offline-state-1: #dc6b00;

    --online-state-2: #304ad2;

    --white: white;
    --black: black;
  }
}

.rdt {
  @apply relative;
}
.rdtPicker {
  @apply hidden absolute w-full min-w-[150px] z-10 shadow-[0_1px_3px_rgba(0,0,0,0.1)] border mt-px p-1 border-solid border-[#f9f9f9];
  background: var(--dark-bg);
}
.rdtOpen .rdtPicker {
  @apply block;
}
.rdtStatic .rdtPicker {
  @apply shadow-none static;
}
.rdtPicker .rdtTimeToggle {
  @apply text-center;
}
.rdtPicker table {
  @apply w-full m-0;
}
.rdtPicker td,
.rdtPicker th {
  @apply text-center h-7;
}
.rdtPicker td {
  @apply cursor-pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  @apply cursor-pointer;
  background: var(--placeholder-dark);
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  @apply text-[#999999];
}
.rdtPicker td.rdtToday {
  @apply relative before:content-[''] before:inline-block before:absolute before:border-l-[7px] before:border-l-transparent before:border-t-[rgba(0,0,0,0.2)] before:border-b-[7px] before:border-b-[#428bca] before:border-solid before:right-1 before:bottom-1;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  @apply bg-[#428bca] text-white;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
  @apply border-b-white;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  @apply text-[#999999] cursor-not-allowed;
  background: none;
}
.rdtPicker td span.rdtOld {
  @apply text-[#999999];
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  @apply text-[#999999] cursor-not-allowed;
  background: none;
}
.rdtPicker th {
  @apply border-b-[#f9f9f9] border-b border-solid;
}
.rdtPicker .dow {
  @apply w-[14.2857%] cursor-default border-b-[none];
}
.rdtPicker th.rdtSwitch {
  @apply w-[100px];
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  @apply text-[21px] align-top;
}
.rdtPrev span,
.rdtNext span {
  @apply block select-none;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}
.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  @apply text-[#999999] cursor-not-allowed;
  background: none;
}
.rdtPicker thead tr:first-of-type th {
  @apply cursor-pointer;
}
.rdtPicker thead tr:first-of-type th:hover {
  background: var(--placeholder-dark);
}
.rdtPicker tfoot {
  @apply border-t-[#f9f9f9] border-t border-solid;
}
.rdtPicker button {
  @apply cursor-pointer border-[none] hover:bg-[#eee];
  background: none;
}
.rdtPicker thead button {
  @apply w-full h-full;
}
td.rdtMonth,
td.rdtYear {
  @apply h-[50px] w-3/12 cursor-pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
  background: var(--placeholder-dark);
}
.rdtCounters {
  @apply inline-block;
}
.rdtCounters > div {
  @apply float-left;
}
.rdtCounter {
  @apply h-[100px] w-10;
}
.rdtCounterSeparator {
  @apply leading-[100px];
}
.rdtCounter .rdtBtn {
  @apply h-2/5 leading-10 cursor-pointer block select-none;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}
.rdtCounter .rdtBtn:hover {
  background: var(--placeholder-dark);
}
.rdtCounter .rdtCount {
  @apply h-1/5 text-[1.2em];
}
.rdtMilli {
  @apply align-middle w-12 pl-2;
}
.rdtMilli input {
  @apply w-full text-[1.2em] mt-[37px];
}
.rdtTime td {
  @apply cursor-default;
}

.popup-content {
  @apply m-auto bg-dark w-1/2 p-[5px] border-[1.5px] border-solid border-white;
}
.popup-content * {
  @apply text-form-content;
}
[role='tooltip'].popup-content {
  @apply w-fit max-w-[350px] shadow-[0_0_3px_rgba(0,0,0,0.16)];
}
.popup-overlay {
  @apply bg-[rgba(0,0,0,0.5)];
}
[data-popup='tooltip'].popup-overlay {
  @apply bg-transparent;
}
.popup-arrow {
  @apply stroke-[3px] stroke-white;
  -webkit-filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, 0.16));
  filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, 0.16));
  stroke-dasharray: 30px;
  stroke-dashoffset: -54px;
}
.popup-arrow path {
  @apply fill-dark;
}
.rti--container {
  @apply form-item-input py-3 !text-form-content !bg-dark w-full;
}

.rti--container:has(> input:disabled) {
  @apply !text-secondary !bg-dark-grey !border-none;
}
.rti--container > .rti--tag {
  @apply !bg-[#303052] !border !border-[#7a7a9f] px-2 text-white;
}

.rti--container > .rti--tag > span {
  @apply text-inherit;
}

.rti--container:has(> input:disabled) > .rti--tag {
  @apply !border-none !text-secondary;
}

.rti--tag > button[type='button'] {
  @apply rounded-full border-[#9191a2] border px-[4px] py-0 text-[10px] h-4 ml-1.5 border-solid hover:border-white text-[#9191a2] font-bold hover:text-white;
}
